<template>
    <BaseForm
        class="call-to-action-email"
        @submit.prevent="onSubmit"
    >
        <BaseFormField
            required
        >
            <template #label>
                <label for="email">Work email</label>
            </template>
            <BaseInput
                id="email"
                v-model="email"
                placeholder="Your institutional email"
                type="email"
                name="email"
                autocomplete="email"
                required
            />

            <BaseButton
                type="submit"
            >
                Join
            </BaseButton>
        </BaseFormField>
    </BaseForm>
</template>

<script setup>
import { useModalStore, MODAL_REGISTER } from '~/store/modal';
import { useAuthStore } from '~/store/auth';

const store = useGeneralStore();
const { email } = storeToRefs(store);
const modalStore = useModalStore();
const authStore = useAuthStore();

const onSubmit = () => {
    authStore.tempEmail = email.value;
    modalStore.toggleModal(MODAL_REGISTER);
};

</script>

<style lang="less">
.call-to-action-email {
    width: 100%;
    flex-shrink: 0;

    .base-input.dn-input {
        width: 100%;
        display: inline-block;
        margin-left: 0;
        margin-right: 0;
    }

    .dn-form-field__wrapper {
        display: flex;
        gap: .5rem;
        width: 100%;
    }

    .dn-form-field {
        width: 100%;
        margin-bottom: 0;
    }

    .dn-button.base-button {
        margin-top: 0;
    }
}
</style>
