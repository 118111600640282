<template>
    <button
        class="search-bar-button"
        @click="modalStore.toggleModal(MODAL_SEARCH)"
    >
        <div>
            <SearchPlaceholder />
        </div>
        <div class="search-bar-button__icon">
            <BaseIcon icon="search" />
        </div>
    </button>
</template>

<script setup>
import { useModalStore, MODAL_SEARCH } from '~/store/modal';

const modalStore = useModalStore();
</script>

<style lang="less" src="./SearchBarButton.less" />
