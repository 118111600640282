<template>
    <PageTemplate>
        <template #hero>
            <BentoHero
                title="Brush up your knowledge with educational videos made by peers."
                data-span-all
                class="bento-hero--transparent"
            />
            <GraphicDevice
                class="image-fill-md"
                data-span-5
            >
                <NuxtPicture
                    src="/images/homepage-1.png"
                    alt=""
                    sizes="xs:100vw, md:40vw"
                    width="300px"
                    height="300px"
                    loading="eager"
                    decoding="async"
                    style="--object-position: top;"
                    :img-attrs="{
                        fetchpriority: 'low'
                    }"
                />
            </GraphicDevice>
            <BentoFrame data-span-3>
                <h2>Create your free account in less than a minute</h2>

                <CallToActionEmail
                    gtm-join
                    gtm-location="hero"
                />
            </BentoFrame>

            <BaseSection
                data-span-all
            >
                <UspSection />
            </BaseSection>
            <BaseSection
                v-if="featuredVideos?.length > 0"
                data-span-all
                class="section--gradient-bg"
            >
                <template #header>
                    <h2>Secure content peer-reviewed by medical professionals.</h2>
                    <p>Here's what to expect</p>
                </template>

                <template #default>
                    <BentoGrid class="bento-grid--bleed">
                        <VideoCard
                            v-for="featuredVideo in featuredVideos"
                            :key="featuredVideo.id"
                            has-bg="always"
                            :video="featuredVideo"
                            data-span-all
                            class="video-card--bg-mobile"
                        >
                            <template #frame="{ video }">
                                <iframe
                                    :title="video.title"
                                    :src="`https://iframe.mediadelivery.net/embed/${activeLibraryId}/${video.bunny_id}?autoplay=false&loop=false&muted=false&preload=true`"
                                    loading="lazy"
                                    allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
                                    allowfullscreen="true"
                                />
                            </template>
                        </VideoCard>
                    </BentoGrid>
                </template>
            </BaseSection>

            <BentoFrame data-span-all>
                <template #image>
                    <GraphicDevice
                        :shape="2"
                        data-span-5
                    >
                        <NuxtPicture
                            src="/images/homepage-2.png"
                            alt=""
                            sizes="xs:100vw, md:40vw"
                            width="300px"
                            height="300px"
                            loading="lazy"
                            decoding="async"
                            style="--object-position: top;"
                            :img-attrs="{
                                fetchpriority: 'low'
                            }"
                        />
                    </GraphicDevice>
                </template>
                <h2>Contribute your own content and share your knowledge</h2>
                <BaseButton
                    gtm-join
                    @click="modalStore.toggleModal(MODAL_REGISTER);"
                >
                    Create account
                </BaseButton>
            </BentoFrame>
            <BaseSection
                data-span-all
                section-width="bleed"
            >
                <FoundingMembers />
            </BaseSection>
            <BaseSection
                data-span-all
                section-width="content"
                class="section--title-lg section--extra-margin"
            >
                <template #title>We're sure you might have some questions</template>
                <BaseAccordion
                    :items="[
                        {
                            title: 'What does the peer-review process entail?',
                            content: 'Uploaded video content will be reviewed by at least two independent experts in your field. Though you will remain responsible for the submitted work, they will check the quality, educational value, scientific integrity and patient anonymity.'
                        },
                        {
                            title: 'How does MedPeers protect privacy?',
                            content: `Patients should provide informed consent prior to the procedure. MedPEERS does not allow the use of patient-identifiable data. For more information, please have a look at our <a href='/legal/privacy'> privacy policy</a>.`
                        },
                        {
                            title: 'How is the security of the platform ensured?',
                            content: `MedPeers implements rigorous security measures to protect patients’ personal and medical data and adheres to strict (inter)national security standards. Please find more information on our <a href='/legal/security'>security and auditing</a> page.`
                        },
                        {
                            title: 'How can I upload my video content?',
                            content: `Please find instructions on how to upload your video content on our <a href='/upload'>upload page</a>.`
                        },
                        {
                            title: 'How can I contact MedPeers?',
                            content: `For all other questions please see our <a href='/faq'>FAQ page</a>, or  get in touch via <a href='mailto:support@medpeers.org'>support@medpeers.org</a>`
                        }
                    ]"
                />
            </BaseSection>
        </template>
    </PageTemplate>
</template>

<script setup>
import { useModalStore, MODAL_REGISTER } from '~/store/modal';
import useAsyncRestApi from '~/composables/useAsyncRestApi';

const modalStore = useModalStore();
const { asset } = useBaseUrl();

const config = useRuntimeConfig();

const activeLibraryId = computed(() => {
    return config.public.mainBunnyLibraryId ?? null;
});

const { data: featuredVideosResponse } = await useAsyncRestApi('videos-featured');

const featuredVideos = computed(() => {
    return toValue(featuredVideosResponse).data;
});

useSeoMeta({
    title: 'MedPeers | Educational platform for healthcare professionals',
    description: 'A secure, peer-reviewed and quality-controlled platform offering educational videos of medical procedures',
    ogImage: asset('/images/og/default.png')
});
</script>
