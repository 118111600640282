<template>
    <PageTemplate class="template--wide template--gap-lg">
        <div class="bento-grid bento-grid--bleed" data-span-all>
            <BaseSection
                class="section--no-margin section--title-lg"
                data-span-4
            >
                <template #title>Welcome, {{ user.first_name }}</template>
                <template #default>
                    <SearchBarButton />
                </template>
            </BaseSection>
        </div>

        <VideoSliderSection
            v-if="sliderVideos && sliderVideos.length > 0"
            data-span-all
            :title="latestInterestsTitle"
            :videos="sliderVideos"
            class="section--no-margin bleed-from-page"
        />

        <HelpMedPeersGrow v-else data-span-4 />

        <VideoGridSection
            v-if="gridVideos && gridVideos.length > 0"
            data-span-all
            :title="moreVideosTitle"
            class="section--no-margin"
            :videos="gridVideos"
        />
    </PageTemplate>
</template>

<script setup>
import { useAuthStore } from '~/store/auth';
import VideosQuery from '~/graphql/queries/videos.graphql';
import VideosCountQuery from '~/graphql/queries/personalVideosCount.graphql';

const { asset } = useBaseUrl();
const authStore = useAuthStore();
const { user } = storeToRefs(authStore);

const sliderVideoCount = 7;
const gridVideoCount = 16;

const userHasInterests = computed(() => {
    return toValue(user)?.specialisations.length > 0;
});

const { data: videosCountResult } = await useAsyncQuery({
    query: VideosCountQuery
});

const hasPersonalVideos = computed(() => {
    return toValue(videosCountResult)?.videos?.paginatorInfo?.total > 0;
});

const inMyInterests = computed(() => {
    return toValue(userHasInterests) && toValue(hasPersonalVideos);
});

const { data } = await useAsyncQuery({
    query: VideosQuery,
    cachePolicy: 'network-only',
    variables: {
        limit: sliderVideoCount + gridVideoCount,
        inMyInterests: toValue(inMyInterests) ? true : undefined
    }
});

const videos = computed(() => {
    return toValue(data)?.videos?.data;
});

const sliderVideos = computed(() => {
    // Don't show any slider videos' when
    // A: the user has no interests selected
    // B: there are no video's in the user's interests
    if (!toValue(userHasInterests) || !toValue(hasPersonalVideos)) {
        return [];
    }

    return toValue(videos)?.slice(0, sliderVideoCount);
});

const gridVideos = computed(() => {
    if (!toValue(inMyInterests)) {
        return toValue(videos);
    }
    
    return toValue(videos)?.slice(sliderVideoCount, toValue(videos).length);
});

const latestInterestsTitle = computed(() => {
    if (!toValue(user) || !toValue(user).specialisations.length) {
        return 'Latest videos';
    }

    if (toValue(user).specialisations.length === 1) {
        return `Latest in ${toValue(user).specialisations[0]?.title.toLowerCase()}`;
    }

    return 'Latest in your interests';
});

const moreVideosTitle = computed(() => {
    if (toValue(userHasInterests) && toValue(sliderVideos).length) {
        return 'More videos within your interests';
    } else if (toValue(userHasInterests) && !toValue(sliderVideos).length) {
        return 'Videos in other specialisations';
    }
});

useSeoMeta({
    title: 'MedPeers | Overview',
    description: 'A secure, peer-reviewed and quality-controlled platform offering educational videos of medical procedures',
    ogImage: asset('/images/og/default.png')
});
</script>
