<template>
    <BentoFrame
        class="help-medpeers-grow bento-grid bento-grid--bleed" 
        dark
    >
        <h2>Help MedPeers grow</h2>
        <p>
            We don’t have video’s matching your interest yet. But don’t worry, MedPeers is a fast growing platform and community.
        </p>
        <p>
            How you can help us grow:
        </p>
        <div class="help-medpeers-grow__actions">
            <BaseButton
                :element="defineNuxtLink({})"
                to="/upload/checklist"
            >
                Submit video content
            </BaseButton>
            <BaseButton
                class="base-button--outline"
                @click="modalStore.toggleModal(MODAL_CONTENT_REQUEST)"
            >
                Request content
            </BaseButton>
        </div>
    </BentoFrame>
</template>

<script setup>
import { useModalStore, MODAL_CONTENT_REQUEST } from '~/store/modal';
const modalStore = useModalStore();
</script>

<style lang="less">
.help-medpeers-grow {
    h2 {
        margin-bottom: var(--spacing-xs);
    }
    
    p {
        .typography-label();
        margin-bottom: var(--spacing-md);
    }
}

.help-medpeers-grow__actions {
    display: flex;
    gap: var(--spacing-xs);
}
</style>