<template>
    <HomeLoggedIn v-if="authStore.loggedIn" />
    <HomeLoggedOut v-else />
</template>

<script setup>
import { useAuthStore } from '~/store/auth';

const authStore = useAuthStore();
</script>
